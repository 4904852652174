import React, { useState, useEffect } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

// componets
import Image from "../../Components/UI/Image";
import Button from "../../Components/UI/Button";
import Modal from "../../Components/Model";
import NewsLetter from "../../Components/newsLetter";

// assets
import AboutImage from "../../assets/images/corporate_insurance_design_2.png";
import ProfileImage from "../../assets/images/default.jpg";
import { leaders, directors } from "./Static_data";

//data
import { statements } from "./Static_data";

function AboutUsPage() {
  const [modal, setModal] = useState(false);
  // useEffect(() => {
  //   const img = new Image();
  //   img.src = "../../assets/images/corporate_insurance_design_2.png";
  // }, []);

  const [memberDetails, setMemberDetails] = useState(null);
  let modalDisplay;

  const handleReadMoreModal = (member) => {
    setMemberDetails(member);
    setModal(true);
  };

  let image = <Image imageSrc={AboutImage} title="about-us" />;

  if (modal && memberDetails) {
    modalDisplay = (
      <Modal>
        <section className="about-container__team__modal">
          <header>
            <div className="container__inner">
              <Button cb={() => setModal(false)}>
                <FontAwesomeIcon icon={faX} className="fa-thin fa-thin" />
              </Button>
            </div>
          </header>

          <main>
            <section className="about-container__team__modal__image-box">
              <div>
                <Image imageSrc={ProfileImage} title="person" />
              </div>
            </section>
            <section className="about-container__team__modal__desc-box">
              <div>
                <label>{memberDetails.name}</label>
                <blockquote>{memberDetails.dist}</blockquote>
              </div>
              <p>{memberDetails.desc}</p>
            </section>
          </main>
        </section>
      </Modal>
    );
  }

  const renderStatements = ({ title, description }) => (
    <div className="container__inner" key={title}>
      <h4 className="d-flex m-end-auto">{title}</h4>
      <p>{description}</p>
    </div>
  );

  return (
    <section className="about-container__outer">
      {modalDisplay}

      <main className="about-container__mission height-vh">
        <div className="container__inner about-container__mission">
          <section className="about-container__mission__text-vessel d-flex-x-y-center d-flex-dir-colum">
            <div className="container__inner">
              <h4 className="d-flex m-end-auto">Our Story</h4>
              <p>
                Coverton Insurance opens its doors with a fresh vision,
                determined to reshape perceptions of insurance. Founded on
                passion and dedication, we stand as a beacon of hope in an
                uncertain world, committed to offering unmatched peace of mind
                to individuals and businesses alike.
              </p>
              <p>
                With a focus on excellence and integrity, Coverton understands
                that insurance is about safeguarding dreams and securing
                futures. Join us as we embark on this journey together, guiding
                families and businesses towards a future of resilience and peace
                of mind.
              </p>
              <p>
                Welcome to Coverton Insurance, where every day brings new
                opportunities to protect what matters most.
              </p>
            </div>
          </section>
        </div>
        <section className="about-container__mission__image-vessel">
          <div className=" d-flex m-end-auto">{image}</div>
        </section>
      </main>
      <section className="about-container__mission__text-vessel d-flex-x-y-center d-flex-dir-colum">
        {statements?.map(renderStatements)}
      </section>
      <footer>
        <section className="about-container__team container__outer">
          <div className="container__inner">
            <h1 className="d-flex-x-y-center">Meet our Business Leaders</h1>
            <section className="about-container__team__cards-vessel">
              {leaders.map((member, ind) => (
                <div
                  key={ind}
                  className="about-container__team__cards-vessel__card-box d-flex-x-y-center d-flex-dir-colum"
                >
                  <section className="about-container__team__cards-vessel__card-box__image-vessel">
                    <Image imageSrc={ProfileImage} title="profile" />
                  </section>
                  <section className="about-container__team__cards-vessel__card-box__text-vessel d-flex-x-y-center d-flex-dir-colum">
                    <label>{member?.name}</label>
                    <blockquote>{member?.dist}</blockquote>
                    <div>
                      <Button cb={() => handleReadMoreModal(member)}>
                        Read More
                      </Button>
                    </div>
                  </section>
                </div>
              ))}
            </section>
          </div>
        </section>
      </footer>
      <NewsLetter />
    </section>
  );
}

export default AboutUsPage;
