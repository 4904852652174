export const statements = [
  {
    title: "Our Mission",
    description:
      "To emerge as a prominent and profitable Indian insurance brokerage firm, prioritizing sustainable business practices to enhance the overall welfare of our clientele.",
  },
  {
    title: "Our Vision",
    description:
      "We envision achieving customer satisfaction by disseminating insurance expertise through innovative, cost-effective e-delivery methods that align closely with the unique insurance requirements of our clients.",
  },
];

export const leaders = [
  {
    name: "S.Swaminathan",
    dist: "Principal Officer",
    desc: "Swaminathan is a seasoned professional with extensive experience in underwriting, risk advisory, and claims handling, with a sound knowledge on Hull, Aviation and tailor made risk coverage and over 30+ years of successful track record, he has worked across various roles in the non-life insurance sector with renewed insurance broking houses.",
  },
  // {
  //   name: "Manikkam",
  //   dist: "Vice President – Tech",
  //   desc: "With 35+ years in Indian Insurance, including time at The Oriental General Insurance Company Ltd and as Insurance consultant, specializing in Fire and Property Insurance. Managed diverse accounts from Textile Mills to Power Plants. As a Sr. Divisional Manager and Risk Engineer, handled underwriting, claims, and reinsurance. Trained at the National Insurance Academy Pune. Empaneled with customs and Income Tax, conducting safety audits, electrical inspections, and valuations to industry standards.",
  // },
  // {
  //   name: "Alagirisamy",
  //   dist: "President",
  //   desc: "Seasoned Life Insurance Professional with 39 years of experience having worked in various Department, including Accounts, Underwriting, Policy Servicing, Loans, Surrenders, Revivals, Claims, Salary Savings Schemes, Sales, Office Services, Maintenance, Legal and Divisional Training Centre in Life insurance corporation of India, retired as Chief Manager (DM) in the year 2017.",
  // },
];

export const directors = [
  {
    name: "S.Swaminathan",
    dist: "Principal Officer",
    desc: "Swaminathan is a seasoned professional with extensive experience in underwriting, risk advisory, and claims handling, with a specific focus on Aviation and Hull reinsurance. With over 30+ years of successful track record, he has worked across various roles in non-life insurance companies and insurance broking houses.",
  },
  {
    name: "Manikkam",
    dist: "Vice President – Tech",
    desc: "With 35+ years in Indian Insurance, including time at The Oriental General Insurance Company Ltd and as Insurance consultant, specializing in Fire and Property Insurance. Managed diverse accounts from Textile Mills to Power Plants. As a Sr. Divisional Manager and Risk Engineer, handled underwriting, claims, and reinsurance. Trained at the National Insurance Academy Pune. Empaneled with customs and Income Tax, conducting safety audits, electrical inspections, and valuations to industry standards.",
  },
  {
    name: "Alagirisamy",
    dist: "Vice President",
    desc: "Seasoned Life Insurance Professional with 39 years of experience having worked in various Department, including Accounts, Underwriting, Policy Servicing, Loans, Surrenders, Revivals, Claims, Salary Savings Schemes, Sales, Office Services, Maintenance, Legal and Divisional Training Centre in Life insurance corporation of India, retired as Chief Manager (DM) in the year 2017.",
  },
  {
    name: "Imthyaz Sheriff C",
    dist: "Principal Officer",
    desc: "Swaminathan is a seasoned professional with extensive experience in underwriting, risk advisory, and claims handling, with a specific focus on Aviation and Hull reinsurance. With over 30+ years of successful track record, he has worked across various roles in non-life insurance companies and insurance broking houses.",
  },
  {
    name: "Karthik Gunasekar",
    dist: "Vice President – Tech",
    desc: "With 35+ years in Indian Insurance, including time at The Oriental General Insurance Company Ltd and as Insurance consultant, specializing in Fire and Property Insurance. Managed diverse accounts from Textile Mills to Power Plants. As a Sr. Divisional Manager and Risk Engineer, handled underwriting, claims, and reinsurance. Trained at the National Insurance Academy Pune. Empaneled with customs and Income Tax, conducting safety audits, electrical inspections, and valuations to industry standards.",
  },
  {
    name: "Srinivasan Vishal",
    dist: "Vice President",
    desc: "Seasoned Life Insurance Professional with 39 years of experience having worked in various Department, including Accounts, Underwriting, Policy Servicing, Loans, Surrenders, Revivals, Claims, Salary Savings Schemes, Sales, Office Services, Maintenance, Legal and Divisional Training Centre in Life insurance corporation of India, retired as Chief Manager (DM) in the year 2017.",
  },
];
