//style
import "./style.scss";

//hooks
import { useState, useEffect } from "react";

const FlippingCard = ({ frontContent, rearContent, auto }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    if (auto) {
      setTimeout(() => setIsFlipped(!isFlipped), 2500);
    }
  });

  const handleCardFlip = () => {
    if (!auto) setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={`card-container ${isFlipped ? "is-flipped" : ""}`}
      onClick={handleCardFlip}
      onMouseEnter={handleCardFlip}
      onMouseLeave={handleCardFlip}
    >
      <div className="card">
        <div className="card-face card-front">{frontContent}</div>
        <div className="card-face card-back">{rearContent}</div>
      </div>
    </div>
  );
};

export default FlippingCard;
