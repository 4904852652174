import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";

// components
import Card from "../../Components/Card";
import Image from "../../Components/UI/Image";
// assets
import ExperienceThirty from "../../assets/Icons/30_years_experience_copy2343-03.svg";
import BQPCertificate from "../../assets/Icons/BQP_certified-cropped.svg";
import Safety from "../../assets/Icons/Safety_Program.svg";
import Inspection from "../../assets/Icons/Periodical_Inspection.svg";

function OverLapContainer() {
  return (
    <section className="home-container__overlap-vessel">
      <header></header>
      <main className="container__inner home-container__overlap-vessel__list">
        <Card customClassess="home-container__overlap-vessel__list__card-item">
          <section className="home-container__overlap-vessel__list__card-item__icon-box">
            {/* <FontAwesomeIcon icon={faFileContract} /> */}
            <Image imageSrc={ExperienceThirty} title="30+ Experence" />
          </section>
          <section className="home-container__overlap-vessel__list__card-item__desc-box">
            <h3>30+ yrs of Professional Experience</h3>
          </section>
        </Card>
        <Card customClassess="home-container__overlap-vessel__list__card-item">
          <section className="home-container__overlap-vessel__list__card-item__icon-box">
            <Image imageSrc={BQPCertificate} title="BQP Certificate" />
          </section>
          <section className="home-container__overlap-vessel__list__card-item__desc-box">
            <h3>IRDA Certified</h3>
          </section>
        </Card>
        <Card customClassess="home-container__overlap-vessel__list__card-item">
          <section className="home-container__overlap-vessel__list__card-item__icon-box">
            <Image imageSrc={Safety} title="Safety" />
          </section>
          <section className="home-container__overlap-vessel__list__card-item__desc-box">
            <h3>Insurance Safety Program</h3>
          </section>
        </Card>
        <Card customClassess="home-container__overlap-vessel__list__card-item">
          <section className="home-container__overlap-vessel__list__card-item__icon-box">
            <Image imageSrc={Inspection} title="Inspection" />
          </section>
          <section className="home-container__overlap-vessel__list__card-item__desc-box">
            <h3>Periodical Inspection Program</h3>
          </section>
        </Card>
      </main>
    </section>
  );
}

export default OverLapContainer;
