import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import ConvertonLogo from "../../../assets/Icons/coverton_logo.svg";
import Image from "../../../Components/UI/Image";
import HeaderNavLink from "./NavLink";

function Header() {
  return (
    <section className="pos-rel header-container__outer">
      <section className="container__inner header-container">
        <Link to="/" className="header-container__image">
          <Image imageSrc={ConvertonLogo} title="logo-company" />
        </Link>
        <div className="header-container__nav-link">
          <HeaderNavLink />
        </div>
      </section>
    </section>
  );
}

export default Header;
