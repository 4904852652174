import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLight,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import "./styles.scss";
//componets
import FooterNavLinkComponent from "./NavLink";
import Image from "../../../Components/UI/Image";

// assets
import { ReactComponent as LOGO } from "../../../assets/Icons/coverton_logo.svg";

function Footer() {
  return (
    <section>
      <section className="footer-container">
        <section className="container__outer">
          <section>
            <section className="footer-container__list-vessel container__inner">
              <div className="footer-container__list-vessel__item-logo">
                <LOGO />
                {/* <Image imageSrc={LOGO} title="logo" />  */}
              </div>
              {/* <div className="footer-container__list-vessel__item-links">
                <h6>QUICK LINKS</h6>
                <FooterNavLinkComponent />
              </div> */}
              <div className="footer-container__list-vessel__item-address">
                <h6>ADDRESS</h6>
                {/* <div>
                  <label>Registered Office Address</label>
                  <p>
                    NO 4A, Dhandeeswaram 10th Street, Velachery, Chennai -
                    600042.
                  </p>
                </div> */}
                <div>
                  {/* <label>Coporate Office Address</label> */}
                  <p>
                    No 190-192, Hameed Complex, Anna Salai, Chennai - 600006
                  </p>
                </div>
              </div>
              <div className="footer-container__list-vessel__item-contact">
                <h6>CONTACT US</h6>
                <div>
                  <p>
                    <span>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <span>wecare@coverton.in</span>
                  </p>
                  <p>
                    <span>
                      <FontAwesomeIcon icon={faPhone} />
                    </span>
                    +91 98408 09568
                  </p>
                </div>
              </div>
            </section>
            <section className="footer-container__description-vessel">
              <section className="container__inner">
                <blockquote>
                  IRDAI License no 961. Direct Broker(Life & General) valid till
                  14th April 2027
                </blockquote>
              </section>
            </section>
          </section>
        </section>
      </section>
      <footer className="footer-container__footer">
        <section className="container__inner">
          <p>
            <strong>Disclaimer:</strong> Insurance is the subject matter of
            solicitation.
          </p>
        </section>
      </footer>
    </section>
  );
}

export default Footer;
