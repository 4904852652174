import React, { useState } from "react";
//style
import "./style.scss";
//icons-&-images
import ic_north_east_arrow from "../../assets/Icons/ic_north_east_arrow.png";
//component
import Image from "../UI/Image";
// data
import { emailRegex } from "../../Utlis/RegExp";

const NewsLetter = () => {
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    !isValid && setIsValid(true);
  };

  const handleEmailClick = () => {
    setIsValid(emailRegex.test(email));
  };
  return (
    <section className="new-letter-wrapper">
      <div className="title-section">
        <h4 className="new-letter-title">Get started with us today.</h4>
        <p className="new-letter-text">
          Subscribe to our newsletter for the latest industry insights, company
          news, and exclusive offers delivered straight to your inbox. Stay
          informed and empowered with Coverton - sign up today!
        </p>
      </div>
      <section className="email-container">
        <div className="input-section">
          <input
            type="email"
            value={email}
            placeholder="Enter Your Email Address"
            onChange={handleEmailChange}
          />
          <button className={"pointer"} onClick={handleEmailClick}>
            <Image
              imageSrc={ic_north_east_arrow}
              customClassess="button-image"
            />
          </button>
        </div>
        {!isValid && <p className="err">Please enter a valid email address</p>}
      </section>
    </section>
  );
};

export default NewsLetter;
