import React from "react";

const ImageComponent = ({ customClassess, imageSrc, title, cb, ...props }) => {
  return (
    <img
      className={`component__image ${customClassess}`}
      src={imageSrc}
      alt={title}
      {...props}
      onClick={cb}
    />
  );
};

export default ImageComponent;
