import "./style.scss";

function LoaderComponent() {
  return (
    <section className="loader-container">
      <div className="loader-container__loader"></div>
    </section>
  );
}

export default LoaderComponent;
