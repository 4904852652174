import { SocialIcon } from "react-social-icons";
import {
  LinkedInLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

// components
import Card from "../../Components/Card";
import Image from "../../Components/UI/Image";
import Button from "../../Components/UI/Button";
import FlippingCard from "../../Components/flippingCard";
// icons
import Date from "../../assets/Icons/Date.svg";
import ConvertonHomeInsurance from "../../assets/images/covernton_home.png";
import img_corp_insurance from "../../assets/images/img_corp_insurance.png";
import img_retail_insurance from "../../assets/images/img_retail_insurace.png";

function SocialLink() {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <section className="home-container__header container__inner">
      <section className="home-container__header__social-icon">
        <Card customClassess="home-container__main__card-item">
          <div className="home-container__main__card-item__text-box">
            <h1>
              Bridge be<span>t</span>ween
            </h1>
            <h1>Insured and Insurer</h1>
            <p>
              COVERTON Insurance Broking Pvt. Ltd. excels in regulatory
              compliance and professionalism, navigating India's insurance
              landscape with innovative confidence.
            </p>
          </div>
          <div className="home-container__main__card-item__button-box">
            <Button customClassess="book-btn">
              <section>
                {/* <div className="home-container__main__card-item__button-box__image-vessel">
                    <Image
                      imageSrc={Date}
                      title="data-icon"
                      customClassess="date-icon"
                    />
                  </div> */}
                <div
                  className="home-container__main__card-item__button-box__text-vessel"
                  onClick={scrollToBottom}
                >
                  <p>Contact us</p>
                </div>
              </section>
            </Button>
          </div>
        </Card>
      </section>
      <section className="home-container__header__image">
        <FlippingCard
          frontContent={
            <Image imageSrc={img_retail_insurance} title="coverton-insurance" />
          }
          rearContent={
            <Image imageSrc={img_corp_insurance} title="coverton-insurance" />
          }
          auto={true}
        />
      </section>
    </section>
  );
}

export default SocialLink;
