import React from "react";
import { Link } from "react-router-dom";
import "../style.scss";

const CustomLink = ({ to, customClassess, children, ...props }) => {
  return (
    <Link to={to} className={`component__link ${customClassess}`} {...props}>
      {children}
    </Link>
  );
};

export default CustomLink;
