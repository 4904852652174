import RetailImage from "../../assets/Icons/Retail_insurance.svg";
import CopoarateImage from "../../assets/Icons/Corporate_insurance.svg";
import HealthIcon from "../../assets/images/health_insurance.png";
import MotorIcon from "../../assets/images/motor_insurance.png";
import TravellIcon from "../../assets/images/travel_insurance.png";
import LifeIcon from "../../assets/images/life_insurance.png";
import PetInsuranceIcon from "../../assets/Icons/Retail_Insurance/pet_insurance.svg";
import JewelryInsuranceIcon from "../../assets/Icons/Retail_Insurance/Jewelry_block_insurance.svg";
import ExternalNaturalHeatingInsuranceIcon from "../../assets/Icons/Retail_Insurance/External&Natural_heating_insurance.svg";
import StormIcon from "../../assets/Icons/Retail_Insurance/Storm_Tempest_Flood_insurance.svg";
import EarthquakeIcon from "../../assets/Icons/Retail_Insurance/Earthquake_insurance.svg";
import LandsideIcon from "../../assets/Icons/Retail_Insurance/Landside_insurance.svg";
import RiotIcon from "../../assets/Icons/Retail_Insurance/Riot_Strike_insurance.svg";
import BoilerIcon from "../../assets/Icons/Retail_Insurance/Boiler_Insurance.svg";
import RobberyIcon from "../../assets/Icons/Retail_Insurance/Theft_Robbery_insurance.svg";
import EquipmentBreakIcon from "../../assets/Icons/Retail_Insurance/Equipment_Break-Down_insurance.svg";
import LegalLiabilityInsurance from "../../assets/Icons/Retail_Insurance/Legal_Liability_insurance.svg";
import AllRickIcon from "../../assets/Icons/Retail_Insurance/All-Risk_&_Tailor-insurance.svg";
import ConsequentialIcon from "../../assets/Icons/Retail_Insurance/Consequential_loss_insurance.svg";

// coporate assets icons
import AviationInsuranceIcon from "../../assets/Icons/Corporate_insurance/Aviation_Insurance.svg";
import EngineeringInsuranceIcon from "../../assets/Icons/Corporate_insurance/engineer_insurance.svg";
import EnergyPowerIcon from "../../assets/Icons/Corporate_insurance/energy_and_power.svg";
import GroupInsuranceIcon from "../../assets/Icons/Corporate_insurance/group_insurance.svg";
import LiabilityInsuranceIcon from "../../assets/Icons/Corporate_insurance/liability_insurance.svg";
import MarineInsuranceIcon from "../../assets/Icons/Corporate_insurance/marine_insurance.svg";
import PropertyInsuranceIcon from "../../assets/Icons/Corporate_insurance/property_insurance.svg";
import ReInsuranceIcon from "../../assets/Icons/Corporate_insurance/reinsurance_insurance.svg";
import HullInsuranceIcon from "../../assets/Icons/Corporate_insurance/Hull_insurance.svg";
import OffshoreInsuranceIcon from "../../assets/Icons/Corporate_insurance/Offshore_insurance.svg";
import ClearingInsuranceIcon from "../../assets/Icons/Corporate_insurance/clearing_insurance.svg";
import CapitationInsuranceIcon from "../../assets/Icons/Corporate_insurance/capitation_insurance.svg";
import healthInsuranceInsuranceIcon from "../../assets/Icons/Corporate_insurance/Health_and_accident_covers.svg";

export const insuranceTypes = [
  {
    title: "Retail Insurance",
    image: RetailImage,
    type: "retail",
    description: "Caring for what matters the most.",
  },
  {
    title: "Corporate Insurance",
    image: CopoarateImage,
    type: "corporate",
    description: "Insuring Business, Ensuring Success.",
  },
];

export const retail_insurances = [
  {
    title: "Life Insurance",
    desc: "Providing financial security and peace of mind to loved ones in the event of the policyholder's passing.",
    image: LifeIcon,
  },
  {
    title: "Motor Insurance",
    desc: "Safeguarding vehicles against accidents, theft, and damage, ensuring smooth journeys for drivers.",
    image: MotorIcon,
  },
  {
    title: "Health Insurance",
    desc: "Offering comprehensive coverage for medical expenses, ensuring access to quality healthcare without financial strain.",
    image: HealthIcon,
  },
  {
    title: "Travel Insurance",
    desc: "Providing protection against unforeseen events during travel, ensuring worry-free adventures.",
    image: TravellIcon,
  },
  {
    title: "Pet Insurance",
    desc: "Caring for beloved furry companions, covering veterinary costs and unexpected medical emergencies.",
    image: PetInsuranceIcon,
  },

  // {
  //   title: "Storm, Tempest, Flood & Inundation",
  //   desc: "Offering coverage against natural disasters such as storms, floods, and inundation, providing peace of mind against property damage.",
  //   image: StormIcon,
  // },
  // {
  //   title: "Earthquake, Tsunami",
  //   desc: "Providing financial protection against seismic events and tsunamis, ensuring quick recovery and rebuilding efforts.",
  //   image: EarthquakeIcon,
  // },
  // {
  //   title: "Landside, Rockside, Subsidence",
  //   desc: "Mitigating risks associated with land movements, protecting properties from landslides, rockslides, and subsidence.",
  //   image: LandsideIcon,
  // },
  // {
  //   title: "Riot, Strike, Malicious, Terrorism",
  //   desc: "Offering coverage against civil unrest, strikes, malicious damage, and acts of terrorism, safeguarding property and assets.",
  //   image: RiotIcon,
  // },
  {
    title: "Fire – Implosion & Explosion (Boiler Insurance)",
    desc: "Ensuring protection against boiler explosions and implosions, minimizing risks and damages.",
    image: BoilerIcon,
  },
  {
    title: "Burglary, Theft, Robbery",
    desc: "Providing coverage against theft, burglary, and robbery, securing assets and property against criminal activities.",
    image: RobberyIcon,
  },

  {
    title: "Legal Liability (Under common & State of Law)",
    desc: "Offering legal protection against liability claims, ensuring financial assistance in legal proceedings. (Workman Compensation)",
    image: LegalLiabilityInsurance,
  },
  {
    title: "Plant & Machinery and Equipment Break-Down",
    desc: "Protecting business assets by covering repair or replacement costs due to machinery breakdowns, ensuring continuity of operations.",
    image: EquipmentBreakIcon,
  },
  {
    title: "Fire & Allied Perils",
    desc: "Secure your business assets with Fire & Allied Perils insurance, shielding against fire outbreaks, vandalism, and other unforeseen risks, fortifying your business resilience. (Residence & Retail Shops)",
    image: ExternalNaturalHeatingInsuranceIcon,
  },
  // {
  //   title: "All-Risk & Tailor-Made cover",
  //   desc: "Providing comprehensive and customizable insurance solutions, tailored to individual needs and risks.",
  //   image: AllRickIcon,
  // },
];

export const corporate_insurance = [
  {
    title: "Aviation Insurance",
    desc: "Offering comprehensive coverage for aircraft operators, ensuring financial protection against risks in the aviation industry.",
    image: AviationInsuranceIcon,
  },
  {
    title: "Engineering Insurance",
    desc: "Providing specialized coverage for construction projects and engineering works, safeguarding against unforeseen damages and liabilities.",
    image: EngineeringInsuranceIcon,
  },
  {
    title: "Energy & Power Insurance",
    desc: "Protecting energy and power sector assets, including oil rigs, power plants, and renewable energy installations, against various risks and losses.",
    image: EnergyPowerIcon,
  },
  {
    title: "Jewellery Block Insurance",
    desc: "Safeguarding valuable jewelry collections against loss, theft, or damage, preserving cherished possessions.",
    image: JewelryInsuranceIcon,
  },
  {
    title: "Group Insurance",
    desc: "Providing coverage for a group of individuals, such as employees of a company, offering benefits like health, life, and accident insurance.",
    image: GroupInsuranceIcon,
  },
  {
    title: "Liability Insurance",
    desc: "Offering protection against legal liabilities arising from third-party claims, ensuring financial security for businesses against lawsuits and damages.",
    image: LiabilityInsuranceIcon,
  },
  {
    title: "Marine Insurance",
    desc: "Safeguarding marine vessels, cargo, and related assets against risks such as piracy, collisions, and natural disasters during transportation.",
    image: MarineInsuranceIcon,
  },
  {
    title: "Consequential loss, Special Contingency covers",
    desc: "Offering coverage for indirect losses and unforeseen contingencies, ensuring financial stability in diverse circumstances.",
    image: ConsequentialIcon,
  },
  {
    title: "Property Insurance",
    desc: "Providing coverage for commercial properties, including buildings, equipment, and inventory, against damages from fire, theft, and other perils.",
    image: PropertyInsuranceIcon,
  },
  // {
  //   title: "Reinsurance Insurance",
  //   desc: "Offering insurance for insurance companies, spreading risks and ensuring financial stability within the insurance industry.",
  //   image: ReInsuranceIcon,
  // },

  {
    title: "Offshore Insurance",
    desc: "Offering specialized coverage for offshore assets and operations, including oil platforms, pipelines, and offshore wind farms, against risks and liabilities unique to offshore environments.",
    image: OffshoreInsuranceIcon,
  },
  {
    title: "Clearing & Forwarding Insurance",
    desc: "Providing insurance coverage for logistics and freight forwarding companies, ensuring protection against losses during the transportation and clearing process.",
    image: ClearingInsuranceIcon,
  },
  {
    title: "Health, Accident and Life support covers",
    desc: "Providing comprehensive insurance solutions for healthcare, accident protection, and life support, ensuring financial assistance during medical emergencies and unexpected events.",
    image: healthInsuranceInsuranceIcon,
  },
  {
    title: "Hull  Insurance",
    desc: "Providing coverage for the physical hull of ships and vessels, protecting against damages from accidents, collisions, and maritime perils.",
    image: HullInsuranceIcon,
  },
  {
    title: "Capitation Insurance",
    desc: "Identify the same line of business consents and Provide insurance coverage overall.",
    image: CapitationInsuranceIcon,
  },
];

export const faq = [
  {
    ques: "Can you provide insurance coverage for my small",
    span: "business",
    ans: "Yes, we offer insurance coverage tailored for small businesses, including general liability insurance, commercial property insurance, and business interruption insurance.",
  },
  {
    ques: "Do you offer insurance packages for specific",
    span: "industries",
    ans: "Yes, we provide industry-specific insurance packages designed to meet the unique needs of various sectors, such as construction, healthcare, hospitality, and manufacturing.",
  },
  {
    ques: "What factors determine the cost of my insurance",
    span: "policy",
    ans: "The cost of your insurance policy depends on factors such as the type of coverage needed, the level of coverage desired, the risk factors associated with your business or personal situation, and your claims history.",
  },
  {
    ques: "Can you explain the coverage limits and deductibles for your insurance",
    span: "policies",
    ans: "Certainly, our insurance policies come with predefined coverage limits and deductibles. Coverage limits determine the maximum amount we will pay for a covered claim, while deductibles are the amount you must pay out of pocket before your insurance coverage kicks in.",
  },
  {
    ques: "Are there any discounts available for bundling multiple insurance ",
    span: "policies",
    ans: "Yes, we offer discounts for bundling multiple insurance policies together. Bundling your home, auto, and other insurance policies with us can help you save money on your premiums.",
  },
  {
    ques: "How do I renew my insurance ",
    span: "policy",
    ans: "Renewing your insurance policy is easy. Simply contact our customer service team before your policy expiration date, and we'll guide you through the renewal process. We can also review your policy to ensure you have the right coverage for your needs.",
  },
];
