import React from "react";

const ButtonComponent = ({ customClassess, children, cb }) => {
  return (
    <button className={`component__button ${customClassess}`} onClick={cb}>
      {children}
    </button>
  );
};

export default ButtonComponent;
