import React from "react";

function ModalComponent({ customClassess, children, cb }) {
  return (
    <section className={`component__modal ${customClassess}`} onClick={cb}>
      <section className="container__inner component__modal__child">
        {children}
      </section>
    </section>
  );
}

export default ModalComponent;
