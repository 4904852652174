import React, { useEffect, useState } from "react";

// components
import Card from "../../Components/Card";
import Image from "../../Components/UI/Image";
import Button from "../../Components/UI/Button";

// icons
import Home from "../../assets/Icons/Home.svg";
import ArrowRight from "../../assets/Icons/ArrowRight.svg";
// static data

function SubSpecialization({ data, setSpecialization }) {
  let SubSpecializationDisplay;

  if (data?.length > 0) {
    SubSpecializationDisplay = data?.map((spec, ind) => {
      return (
        <div
          key={ind}
          className={`home-container__sub-specialization__list-vessel__item-box fade-in-bottom-to-top`}
        >
          <div className="home-container__sub-specialization__list-vessel__item-box__icon-cont">
            <div>
              <Image imageSrc={spec?.image} title="home-icon" />
            </div>
          </div>
          <div className="home-container__sub-specialization__list-vessel__item-box__text-cont">
            <section className="container__inner">
              <label>{spec?.title}</label>
              <p>{spec?.desc}</p>
            </section>
          </div>
        </div>
      );
    });
  }

  return (
    <section className="home-container__sub-specialization fade-in-bottom-to-top">
      <section className="home-container__sub-specialization__list-vessel">
        {SubSpecializationDisplay}
      </section>
    </section>
  );
}

export default SubSpecialization;
