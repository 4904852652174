import React from "react";

import CustomLink from "../../../Components/UI/Link";
import { headerLinks } from "./staticData";
import { useLocation } from "react-router-dom";

function HeaderNavLink() {
  const { pathname } = useLocation();

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  let displayNavLink = headerLinks.map((link, ind) => {
    return (
      <li key={ind}>
        {link.to ? (
          <CustomLink to={link.to} children={link.name} />
        ) : (
          <CustomLink onClick={scrollToBottom} children={link.name} />
        )}
      </li>
    );
  });

  return <ul className="nav-link__header">{displayNavLink}</ul>;
}

export default HeaderNavLink;
