import React from "react";

function CardComponent({ customClassess, children, cb }) {
  return (
    <section className={`component__card ${customClassess}`} onClick={cb}>
      <section className="container__inner">{children}</section>
    </section>
  );
}

export default CardComponent;
