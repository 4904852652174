import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

// style
import "./style.scss";

// components
import Card from "../../Components/Card";
import Image from "../../Components/UI/Image";
import Button from "../../Components/UI/Button";
import NewsLetter from "../../Components/newsLetter";
import Modal from "../../Components/Model";
// icons
import Time from "../../assets/Icons/Time.svg";
import Health from "../../assets/Icons/Health.svg";
import Date from "../../assets/Icons/Date.svg";
import WhatUp from "../../assets/Icons/Whatup.svg";

// sub components
import Specialization from "./Specialization";
import SubSpecialization from "./SubSpecialization";
import SocialLink from "./SocialLink";
import OverLapContainer from "./OverLapCont";
import FAQ from "./FAQ";

// static data
import { retail_insurances, corporate_insurance } from "./StaticData";

function Home() {
  const [specialization, setSpecialization] = useState(null);

  useEffect(() => {
    if (specialization) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto"; // Reset body overflow when unmounting
    };
  }, [specialization]);

  let currentSubSpec;

  if (specialization) {
    currentSubSpec = (
      <Modal>
        <section className="home-container__sub-specialization__outer">
          <header className="fade-in-bottom-to-top">
            <section className="home-container__sub-specialization__text-vessel">
              <h2>Our Specialization</h2>
            </section>
            <Button cb={() => setSpecialization(null)}>
              <FontAwesomeIcon icon={faX} className="fa-thin fa-thin" />
            </Button>
          </header>
          <SubSpecialization
            setSpecialization={setSpecialization}
            data={
              specialization === "retail"
                ? retail_insurances
                : corporate_insurance
            }
          />
        </section>
      </Modal>
    );
  }

  return (
    <section className="home-container">
      <section className="container__outer pos-relative">
        {/* <h5 className="pos-absol temp-dev">
          The website is still under development
        </h5> */}
        <SocialLink />
      </section>
      <OverLapContainer />
      <section className="home-container__main">
        <Specialization
          currentSpec={specialization}
          setCurrentSpec={setSpecialization}
        />
      </section>
      {currentSubSpec}
      <FAQ />
      <NewsLetter />
    </section>
  );
}

export default Home;
