import React, { useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faChevronRight,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

// assets
import FAQ_Image from "../../assets/images/Faq_image.png.png";
// components
import Card from "../../Components/Card";
import Image from "../../Components/UI/Image";
// static data
import { faq } from "./StaticData";

function FAQ() {
  const [ques, setQues] = useState(null);

  const handleFaqFlip = (id) => {
    ques === id ? setQues(null) : setQues(id);
  };

  let questions = faq.map((item, ind) => {
    return (
      <section
        key={ind}
        className="faq-container__questions-vessel__list__item"
        onClick={() => handleFaqFlip(ind)}
      >
        <section
          className={
            ques === ind
              ? "faq-container__questions-vessel__list__item__ques-box  card-active"
              : "faq-container__questions-vessel__list__item__ques-box"
          }
        >
          <div className="container__inner">
            <p>
              {item?.ques}
              <span>{item?.span}?</span>
            </p>
            <FontAwesomeIcon
              icon={ques === ind ? faChevronUp : faChevronDown}
              className="fa-thin"
            />
          </div>
        </section>
        {ques === ind && (
          <section className="faq-container__questions-vessel__list__item__ans-box">
            <p className="container__inner">{item?.ans}</p>
          </section>
        )}
      </section>
    );
  });

  return (
    <section className="container__outer">
      <section className="faq-container container__inner">
        <section className="faq-container__image-cont">
          <section className="container__inner">
            <Image imageSrc={FAQ_Image} title="faq" />
          </section>
        </section>
        <section className="faq-container__desc-cont">
          <section className="container__inner">
            <section className="faq-container__heading-vessel">
              <header></header>
              <main>
                <h1>
                  <span>Curious Minds Welcome: </span> FAQ - Your Guide to
                  Coverton
                </h1>
              </main>
              <footer>
                <p>
                  Welcome to the COVERTON Insurance FAQ section, where you can
                  find answers to commonly asked questions about our insurance
                  services. Whether you're curious about coverage options,
                  claims processes, or policy details, you'll find helpful
                  information here. If you have a question that's not addressed
                  in our FAQ, please don't hesitate to reach out to our team for
                  assistance.
                </p>
              </footer>
            </section>
            <section className="faq-container__questions-vessel">
              <h5>General Questions:</h5>
              <section className="faq-container__questions-vessel__list d-flex d-flex-dir-colum">
                {questions}
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
  );
}

export default FAQ;
