// components
import Card from "../../Components/Card";
import Image from "../../Components/UI/Image";
import Button from "../../Components/UI/Button";
// icons
import Time from "../../assets/Icons/Time.svg";
import Health from "../../assets/Icons/Health.svg";
import Date from "../../assets/Icons/Date.svg";
import WhatUp from "../../assets/Icons/Whatup.svg";
// data
import { insuranceTypes } from "./StaticData";
// assets
import CovertonText from "../../assets/images/coverton-text-logo.png";
import InsuranceCoprate from "../../assets/Icons/Periodical_Inspection.svg";

function Specialization({ currentSpec, setCurrentSpec }) {
  const handleSetSpec = (spec) => {
    window.scrollTo(0, 0);
    setCurrentSpec("");
    setTimeout(() => {
      setCurrentSpec(spec);
    });
  };

  const renderInsuranceTypes = ({ title, image, type, description }) => (
    <Card customClassess="home-container__main__card-item" key={title}>
      <div className="home-container__main__card-item__icon-box">
        <Image imageSrc={image} />
      </div>
      <div className="home-container__main__card-item__text-box">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <div className="home-container__main__card-item__button-box">
        {currentSpec !== type && (
          <Button customClassess="book-btn" cb={() => handleSetSpec(type)}>
            <section>
              <div className="home-container__main__card-item__button-box__text-vessel">
                <p>Know More</p>
              </div>
            </section>
          </Button>
        )}
      </div>
    </Card>
  );

  return (
    <section className="container__inner">
      <div className="home-container__main__text">
        <h1>
          Welcome to
          <span>
            <Image imageSrc={CovertonText} title="coverton-text" />
          </span>
        </h1>
        <p>
          COVERTON Insurance Broking Pvt. Ltd. is poised to revolutionize the
          insurance broking landscape, leveraging unparalleled expertise to
          seize untapped potential in India's evolving insurance market.
        </p>
      </div>
      <div className="home-container__main__card">
        {insuranceTypes?.map(renderInsuranceTypes)}
      </div>
    </section>
  );
}

export default Specialization;
